<template>
    <div class="modal" v-if="modelValue" :class="{ active : modelValue }">
        <div class="modal_container">
            <div class="modal_header border">
                <div class="modal_info">
                    <h1>Stop Workout</h1>
                </div>
                <button class="close_btn left_out" @click="closeModal()"><i class="fas fa-long-arrow-alt-right"></i></button>
            </div>
            <div class="modal_body">
                <Form @submit="handleStopWorkout" v-slot="{ errors }">
                    <div class="setting_wpr">
                        <div class="form_grp">
                            <div class="group_item">
                                <label class="input_label">Select Workout</label>
                                <div class="field_wpr" :class="{ 'has-error': errors.workout }">
                                    <Field autocomplete="off" name="workout" v-model="form.ids" rules="required" label="workout">
                                        <multiselect
                                            v-model="form.ids"
                                            label="name"
                                            value-prop="id"
                                            :searchable="true"
                                            :options="workouts"
                                            placeholder="Select Workout"
                                            mode="tags"
                                        ></multiselect>
                                    </Field>
                                </div>
                                <ErrorMessage name="workout" class="text-danger" />
                            </div>
                        </div>
                        <div v-show="form.ids.length">
                            <schedule-component :schedule-data="form" title="workout" :close-modal="closeModal" />
                        </div>
                        <div class="action_wpr mt-5">
                            <button :disabled="bulkActionLoader" type="button" class="btn cancel_btn" @click="closeModal()">Cancel</button>
                            <button :disabled="bulkActionLoader" class="btn save_btn"><i class="fa fa-spinner fa-spin" v-if="bulkActionLoader"></i>{{ bulkActionLoader ? ' Stoping' : 'Stop' }}</button>
                        </div>
                    </div>
                </Form>
            </div>
        </div>
    </div>
</template>

<script>
    import { defineAsyncComponent } from 'vue'
    import { Field, Form, ErrorMessage } from 'vee-validate'
    import { mapState, mapActions } from 'vuex'

    const ScheduleComponent = defineAsyncComponent(() => import('@/components/Schedule'))

    import Helper from '@/utils/Helper'
    import Swal from 'sweetalert2'

    export default {
        name: 'Stop Workout',

        data () {
            return {
                form: {
                    contacts: '',
                    ids: [],
                    action: ' /workout/unassign-from-contacts',
                },
            }
        },

        props: {
            modelValue: Boolean,
            contacts: [Object, Array],
        },

        emits: ['update:modelValue'],

        watch: {
            modelValue (val) {
                const vm = this;

                if (val == true) {
                    vm.form.contacts = vm.contacts;
                    document.body.classList.add('modal-open');
                } else {
                    document.body.classList.remove('modal-open');
                }

                if (vm.workouts.length == 0) {
                    vm.getAllWorkouts();
                }
            },
        },

        components: {
            Form,
            Field,
            ErrorMessage,
            ScheduleComponent,
        },

        computed: {
            ...mapState({
                workouts: state => state.workoutModule.allWorkouts,
                bulkActionLoader: state => state.contactModule.bulkActionLoader,
            }),
        },

        mounted () {
            const vm = this;

            vm.resetForm();
        },

        methods: {
            ...mapActions({
                getAllWorkouts: 'workoutModule/getAllWorkouts',
                stopWorkout: 'workoutModule/stopWorkout',
            }),

            closeModal () {
                const vm = this;

                vm.resetForm();
                vm.$emit('update:modelValue', false);
            },

            resetForm () {
                const vm = this;

                vm.form = {
                    contacts: '',
                    ids: [],
                    action: ' /workout/unassign-from-contacts',
                };
            },

            handleStopWorkout (params, { setFieldError }) {
                const vm = this;

                vm.form.setFieldError = setFieldError;
                vm.form.closeModal = vm.closeModal;

                const option = Helper.swalConfirmOptions('Are You Sure?', "You want to stop workout for selected contacts?")

                option.preConfirm = function() { return vm.stopWorkout(vm.form) };

                Swal.fire(option);
            },
        },
    }
</script>
